import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ticket: localStorage?.getItem("url"),
};
export const ticketUrl = createSlice({
  name: "ticketUrl",
  initialState: initialState,
  reducers: {
    addTicketUrl: (state, action) => {
      state.ticket = action.payload;
      localStorage.setItem("url", action.payload);
    },
  },
});
export const { addTicketUrl } = ticketUrl.actions;
export default ticketUrl.reducer;
