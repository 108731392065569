import { useSelector } from "react-redux";
import { ApplicationsForm } from "../components/applicationsForm/applicationsForm";
import { CameraForm } from "../components/cameraForm/cameraForm";
import { Header } from "../components/header/header";
import { Quote } from "../components/quote/quote";
import "./notHere.css";
export const ApplicationPage = () => {
  const { theme } = useSelector((state) => state.themeChanger);
  const { formState } = useSelector((state) => state.form);
  return (
    <>
      {formState?.problemType === "applications" ? (
        <div
          style={{
            height: "130vh",
            // color: `${theme === "light" ? "white" : "white"}`,
            background: "rgb(216,217,221)",
            background:
              "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",
          }}
        >
          <Header />
          <Quote />
          <ApplicationsForm />
        </div>
      ) : (
        <div
          style={{
            height: "130vh",
            color: `${theme === "light" ? "black" : "white"}`,
            background: `${theme === "light" ? "rgb(20,87,230)" : "#242526"}`,
          }}
        >
          <Header />
          <Quote />
          {/* <div>You are not supposed to be here !!</div> */}
          <section className="col-12">
            <div className="alert alert-2-success">
              <h3 className="alert-title">OPPS!!</h3>
              <p className="alert-content">
                You are not supposed to be here !!
              </p>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
