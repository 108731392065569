import axios from "axios";
import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { printerHandler } from "../../redux/formSlice";
import { FinalForm } from "../finalForm/finalForm";
import { NoIdPrinter } from "../noIdPrinter/noIdPrinter";

export const PrinterForm = () => {
  const dispatch = useDispatch();
  const { formState } = useSelector((state) => state.form);
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState("Select a printer");
  useEffect(() => {
    const fetchDevice = async () => {
      axios
        .get(`https://qr.3f.com/api/devices/${formState.deviceId}`)
        .then((response) => setPrinters(response.data.Printers))
        .catch((error) => console.log(error));
    };
    fetchDevice();
  }, []);
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate("/details");
  };
  const SelectPrinterHandler = (uuid, name) => {
    setSelectedPrinter(name);
    dispatch(printerHandler(uuid));
  };
  return (
    <>
      {formState.deviceId === "" || formState.deviceId === ":id" ? (
        <NoIdPrinter />
      ) : (
        <>
          <FinalForm showIt={true} uploadPhoto={true} submitBtn={true} />
        </>
      )}
    </>
  );
};
