import axios from "axios";
import "./scaleFinalForm.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTicketUrl } from "../../redux/ticketSlice";
import {
  attachmentsHandler,
  descriptionHandler,
  titleHandler,
} from "../../redux/formSlice";
import { UploadPicture } from "../uploadPicture/uploadPicture";
import { Loader } from "../loader/loader";

export const ScaleFinalForm = () => {
  const { formState } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const [loading, setLoading] = useState(false);

  const galleryImageHandler = (imagesData) => {
    setImages(imagesData);
    // console.log(images);
  };

  const submitTicketHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("username", formState.username);
    formData.append("email", formState.email);
    formData.append("phone", formState.phone);
    formData.append("problemType", formState.problemType);
    formData.append("issueDescription", formState.issueDescription);
    if (formState.deviceId === "" || formState.deviceId === ":id") {
    } else {
      formData.append("deviceId", formState.deviceId);
    }
    formData.append("titleIssue", formState.titleIssue);
    formData.append("attachment_camera", formState.liveCamera);
    formData.append("scale_name", formState.scale_name);
    formData.append("scale_location", formState.scale_location);
    if (
      formState.typeOfDevice === "applications" ||
      formState.typeOfDevice === "others"
    ) {
    } else {
      formData.append("type_of_device", formState.typeOfDevice);
    }
    for (let i = 0; i < images.length; i++) {
      formData.append("attachments_gallery", images[i]);
    }

    await axios
      .post("https://qr.3f.com/api/tickets", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // console.log(response);
        if ((response.data.message = "success")) {
          dispatch(addTicketUrl(response.data));
        } else {
          // dispatch(addTicketUrl(response.data.message));
          // console.log("failed");
        }
      })
      .catch((error) => dispatch(addTicketUrl(error.message)))
      .finally(() => {
        setLoading(false);
        navigate("/success");
      });
  };
  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className="scalefinal">
      <div className="scalefinal__div">
        <UploadPicture onChoose={galleryImageHandler} />
        <div className="form-group">
          <button
            type="button"
            className="btn btn-block create-account"
            onClick={submitTicketHandler}
            disabled={!formState.scale_name}
          >
            Submit ticket
          </button>
        </div>
      </div>
    </div>
  );
};
