import { useState } from "react";
import { FinalForm } from "../finalForm/finalForm";
import { SelectSearch } from "../selectSearch/selectSearch";
import { useDispatch } from "react-redux";
import {
  scaleNameHandler,
  scaleLocationHandler,
  titleHandler,
  descriptionHandler,
} from "../../redux/formSlice";
import axios from "axios";
import { ScaleFinalForm } from "../scaleFinalForm/scaleFinalForm";

export const ScalesForm = () => {
  const [isForm, setIsForm] = useState(false);
  const [scales, setScales] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const dispatch = useDispatch();
  const chooseScaleForm = (info) => {
    setIsForm(info);
  };
  const selectedValue = async (theValue) => {
    setBtnDisabled(false);
    await getScalesByLocation(theValue.value);
    dispatch(scaleLocationHandler(theValue.value));
    dispatch(titleHandler("scale issue"));
    dispatch(descriptionHandler("scale issue"));
  };

  const scaleNameValue = (theValue) => {
    // console.log(theValue);
    dispatch(scaleNameHandler(theValue.value));
  };
  const getScalesByLocation = (loc) => {
    axios
      .get(`https://qr.3f.com/api/scales/scalesByLocation/${loc}`)
      .then((res) => {
        const newArray = res.data.data.map((item) => {
          return {
            label: item.scale_name,
            value: item.scale_name,
          };
        });

        setScales(newArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const locations = [
    {
      id: 0,
      label: "Oakland",
      value: "oakland",
    },
    {
      id: 1,
      label: "Nevada",
      value: "nevada",
    },
    {
      id: 2,
      label: "Post Falls",
      value: "postfalls",
    },
    {
      id: 3,
      label: "Austin",
      value: "austin",
    },
    {
      id: 4,
      label: "Newark",
      value: "newark",
    },
    {
      id: 5,
      label: "Los Angelos",
      value: "losangelos",
    },
  ];
  return (
    <div className="row">
      <div className="col-12 text-black ">
        {isForm ? (
          <>
            <div className="col-12 text-white text-center">
              Which Scale are you having an issue with
            </div>
            <div>
              <SelectSearch
                showIt={false}
                options={scales}
                onChoose={scaleNameValue}
              />
              {/* <FinalForm showIt={false} uploadPhoto={true} submitBtn={true} /> */}
              <ScaleFinalForm />
            </div>
          </>
        ) : (
          <>
            {" "}
            <SelectSearch
              problemName="Scale"
              options={locations}
              onChoose={selectedValue}
              onConfirm={chooseScaleForm}
              showIt={true}
              disabled={btnDisabled}
            />
          </>
        )}
      </div>
    </div>
  );
};
