import axios from "axios";
import { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  attachmentsHandler,
  descriptionHandler,
  titleHandler,
} from "../../redux/formSlice";
import { addTicketUrl } from "../../redux/ticketSlice";
import { Faq } from "../faq/faq";
import { LiveCamera } from "../liveCamera/liveCamera";
import galleryIcon from "../../assets/images/galleryIcon.png";
import { Loader } from "../loader/loader";
import "./finalForm.css";
import { UploadPicture } from "../uploadPicture/uploadPicture";
export const FinalForm = ({ showIt, submitBtn, uploadPhoto }) => {
  const { formState } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const descriptionInput = (e) => {
    dispatch(descriptionHandler(e.target.value));
  };
  const issueTitleHandler = (e) => {
    dispatch(titleHandler(e.target.value));
  };
  const galleryImageHandler = (imagesData) => {
    setImages(imagesData);
    // console.log(images);
  };

  const submitTicketHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("username", formState.username);
    formData.append("email", formState.email);
    formData.append("phone", formState.phone);
    formData.append("problemType", formState.problemType);
    formData.append("assignedPrograms", formState.assignedPrograms);
    formData.append("issueDescription", formState.issueDescription);
    if (formState.deviceId === "" || formState.deviceId === ":id") {
    } else {
      formData.append("deviceId", formState.deviceId);
    }
    formData.append("titleIssue", formState.titleIssue);
    formData.append("assignedPrinters", formState.assignedPrinters);
    formData.append("attachment_camera", formState.liveCamera);
    formData.append("scale_name", formState.scale_name);
    formData.append("scale_location", formState.scale_location);
    formData.append("doorProblem", formState.doorProblem);
    formData.append("person_type", formState.person_type);
    formData.append("first_name", formState.first_name);
    formData.append("last_name", formState.last_name);
    formData.append("company", formState.company);
    formData.append("subProblem", formState.subproblem);
    formData.append("department", formState.department);
    formData.append("door_name", formState.door_name);
    formData.append("door_location", formState.door_location);
    if (
      formState.typeOfDevice === "applications" ||
      formState.typeOfDevice === "others"
    ) {
    } else {
      formData.append("type_of_device", formState.typeOfDevice);
    }
    formData.append("printer_name", formState.noIdPrinter.printer_name);
    formData.append("location", formState.noIdPrinter.location);
    formData.append("cameras", JSON.stringify(formState.assignedCameras));
    for (let i = 0; i < images.length; i++) {
      formData.append("attachments_gallery", images[i]);
    }

    await axios
      .post("https://qr.3f.com/api/tickets", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // console.log(response);
        if ((response.data.message = "success")) {
          dispatch(addTicketUrl(response.data));
        } else {
          // dispatch(addTicketUrl(response.data.message));
          // console.log("failed");
        }
      })
      .catch((error) => dispatch(addTicketUrl(error.message)))
      .finally(() => {
        setLoading(false);
        navigate("/success");
      });
  };
  if (loading === true) {
    return <Loader />;
  }
  return (
    <>
      <div className="registration-form">
        <form>
          {showIt && (
            <>
              <div className="form-group">
                <label className="m-2">
                  Please specify the main subject of the issue you're reporting
                </label>
                <input
                  type="text"
                  required
                  className="form-control item"
                  placeholder="please type the title of the issue"
                  onChange={issueTitleHandler}
                />
              </div>
              <div className="form-group">
                <label className="mb-2">
                  Can you please provide a detailed description of the issue
                  you're experiencing
                </label>
                <textarea
                  required
                  type="text"
                  className="form-control item"
                  style={{ height: "100px" }}
                  name="issueDescription"
                  onChange={descriptionInput}
                />
              </div>
            </>
          )}
          {uploadPhoto ? (
            <>
              <UploadPicture onChoose={galleryImageHandler} />
            </>
          ) : (
            <></>
          )}

          {submitBtn ? (
            <>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block create-account"
                  onClick={submitTicketHandler}
                  disabled={
                    !formState.titleIssue || !formState.issueDescription
                  }
                >
                  Submit ticket
                </button>
              </div>
            </>
          ) : (
            <></>
          )}

          <Row>
            {formState?.problemType === "device" ||
            formState?.problemType === "printer" ||
            formState?.problemType === "applications" ||
            formState?.problemType === "camera" ? (
              <Faq />
            ) : (
              <></>
            )}
          </Row>
        </form>
      </div>
    </>
  );
};
