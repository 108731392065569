import "./secondForm.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { problemTypeHandler, typeOfDeviceHandler } from "../../redux/formSlice";
import deviceIcon from "../../assets/images/device.png";
import appletvIcon from "../../assets/images/appletv.png";
import cameraIcon from "../../assets/images/cam.png";
import decoderIcon from "../../assets/images/decoder.png";
import printerIcon from "../../assets/images/printer.png";
import appsIcon from "../../assets/images/apps.png";
import deskPhonesIcon from "../../assets/images/deskphone.png";
import ipadIcon from "../../assets/images/ipad.png";
import macbookIcon from "../../assets/images/macbook.png";
import scaleIcon from "../../assets/images/scale.png";
import cctvIcon from "../../assets/images/cctvcamera.png";
import doorIcon from "../../assets/images/doors_white.png";
import securityBadgeIcon from "../../assets/images/securityBadge.png";
// const firstProblems = [
//   {
//     id: 1,
//     name: "Device",
//     type: "device",
//     image: deviceIcon,
//     alt: "device icon",
//   },
//   {
//     id: 2,
//     name: "Apple Tv",
//     type: "device",
//     image: appletvIcon,
//     alt: "apple tv icon",
//   },
//   {
//     id: 3,
//     name: "Camera",
//     type: "camera",
//     image: cctvIcon,
//     alt: "camera icon",
//   },
//   {
//     id: 4,
//     name: "Decoder",
//     type: "device",
//     image: decoderIcon,
//     alt: "decoder icon",
//   },
//   {
//     id: 5,
//     name: "Printer",
//     type: "printer",
//     image: printerIcon,
//     alt: "printer icon",
//   },
// ];
const firstProblems = [
  {
    id: 1,
    name: "Doors",
    type: "doors",
    image: doorIcon,
    alt: "doors",
  },
];

const secondProblems = [
  {
    id: 2,
    name: "Badges",
    type: "securityBadges",
    image: securityBadgeIcon,
    alt: "securityBadges",
  },
];
// const secondProblems = [
//   {
//     id: 6,
//     name: "Applications",
//     type: "applications",
//     image: appsIcon,
//     alt: "apps icon",
//   },
//   {
//     id: 7,
//     name: "Desk phones",
//     type: "device",
//     image: deskPhonesIcon,
//     alt: "desk phones icon",
//   },
//   { id: 8, name: "Ipads", type: "device", image: ipadIcon, alt: "ipad icon" },
//   {
//     id: 9,
//     name: "Macbook",
//     type: "device",
//     image: macbookIcon,
//     alt: "macbook icon",
//   },
//   {
//     id: 10,
//     name: "Scales",
//     type: "scales",
//     image: scaleIcon,
//     alt: "others icon",
//   },
//   // {
//   //   id: 11,
//   //   name: "Physical Security Badges",
//   //   type: "Physical Security Badges",
//   //   image: othersIcon,
//   //   alt: "badge icon",
//   // },
// ];

const thirdProblems = [
  {
    id: 11,
    name: "Physical Security Badges",
    type: "securityBadges",
    image: securityBadgeIcon,
    alt: "securityBadges",
  },
];
export const SecondForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const typeInput = (problem, deviceType) => {
    dispatch(problemTypeHandler(problem));
    dispatch(typeOfDeviceHandler(deviceType.toLowerCase()));
    if (problem === "device") {
      navigate("/details");
    } else {
      navigate(`/${problem}`);
    }
  };
  return (
    <div className="container-fluid text-black">
      <div className="m-2 fs-5 mt-sm-5 d-flex justify-content-center">
        Please specify the issue:
      </div>

      <div className="row">
        <div className=" text-center d-flex justify-content-center">
          {firstProblems.map((problem) => (
            <div key={problem.id} className="row-test">
              <div className="column-test">
                {/* <div className="ag-courses-item_date-box"> */}
                <img
                  onClick={() => typeInput(problem.type, problem.name)}
                  className="mx-auto"
                  src={problem.image}
                  alt={problem.alt}
                  width="200px"
                />
                {/* </div> */}
                <p>{problem.name}</p>
              </div>
            </div>
          ))}
        </div>
        <div className=" text-center d-flex justify-content-center">
          {secondProblems.map((problem) => (
            <div key={problem.id} className="row-test">
              <div key={problem.id} className="column-test">
                {/* <div className="ag-courses-item_bg"></div> */}

                {/* <div className="ag-courses-item_date-box"> */}
                <img
                  onClick={() => typeInput(problem.type, problem.name)}
                  className="mx-auto"
                  src={problem.image}
                  alt={problem.alt}
                  width="200px"
                />
                {/* </div> */}
                <p>{problem.name}</p>
              </div>
            </div>
          ))}
        </div>

        {/* <div className=" text-center col-md-12 col-lg-12 col-6 d-lg-flex d-md-flex justify-content-center">
          {thirdProblems.map((problem) => (
            <div className="ag-courses_item">
              <div
                key={problem.id}
                onClick={() => typeInput(problem.type, problem.name)}
                className="ag-courses-item_link"
              >
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_date-box">
                  <img
                    className="mx-auto"
                    src={problem.image}
                    alt={problem.alt}
                    width="100px"
                  />
                </div>
                <div className="ag-courses-item_title">{problem.name}</div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};
