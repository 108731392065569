export const ProgressBar = ({ barWidth, btn1Color, btn2Color, btn3Color }) => {
  return (
    <div className="position-relative m-4  bg-primary col-8 col-md-3 ">
      <div
        className="progress bg-white"
        role="progressbar"
        aria-label="Progress"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ height: "3px" }}
      >
        <div className="progress-bar" style={{ width: barWidth }}></div>
      </div>
      <div className=" bg-success">
        <button
          type="button"
          className={`position-absolute top-0 start-0 translate-middle btn btn-sm ${btn1Color} rounded-pill`}
          style={{ width: "2rem", height: "2rem" }}
        >
          1
        </button>
        <button
          type="button"
          className={`position-absolute top-0 start-50 translate-middle btn btn-sm ${btn2Color}  rounded-pill`}
          style={{ width: "2rem", height: "2rem" }}
        >
          2
        </button>
        <button
          type="button"
          className={`position-absolute top-0 start-100 translate-middle btn btn-sm ${btn3Color}  rounded-pill`}
          style={{ width: "2rem", height: "2rem" }}
        >
          3
        </button>
      </div>
    </div>
    // <div className="position-relative container-fluid justify-content-center bg-white">
    //   <div className=" d-flex justify-content-around bg-danger">
    //     <p className="py-2 px-3 rounded-circle bg-primary ">1</p>
    //     <p className="py-2 px-3 rounded-circle bg-primary ">2</p>
    //     <p className="py-2 px-3 rounded-circle bg-primary ">3</p>
    //   </div>
    //   <div
    //     className="position-absolute z-50 bg-success rounded"
    //     style={{ height: "10px" }}
    //   ></div>
    // </div>
  );
};
