import { useSelector } from "react-redux";
import { Header } from "../components/header/header";
import { PageTransition } from "../components/pageTransition/pageTransition";
import { ProgressBar } from "../components/progressBar/progressBar";
import { Quote } from "../components/quote/quote";
import { SecondForm } from "../components/secondForm/secondForm";

export const SecondPage = () => {
  const { theme } = useSelector((state) => state.themeChanger);

  return (
    <PageTransition>
      <div
        className="vh-100 d-flex flex-column align-items-center"
        style={{
          height: "150vh",
          background: "rgb(216,217,221)",
          background:
            "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",

          // color: `${theme === "" ? "black" : "white"}`,
        }}
      >
        <div className="align-self-start">
          <Header />
        </div>
        <ProgressBar
          barWidth={"50%"}
          btn1Color={"btn-primary"}
          btn2Color="btn-primary"
          btn3Color={"btn-secondary"}
        />
        <SecondForm />
      </div>
    </PageTransition>
  );
};
