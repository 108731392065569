import { useSelector } from "react-redux";
import { Header } from "../components/header/header";
import { PageTransition } from "../components/pageTransition/pageTransition";
import { Success } from "../components/success/success";

export const Details = () => {
  const { theme } = useSelector((state) => state.themeChanger);
  return (
    <PageTransition>
      <div
        style={{
          height: "130vh",
          // color: `${theme === "light" ? "black" : "black"}`,
          background: "rgb(216,217,221)",
          background:
            "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",
        }}
      >
        <Header />
        <Success />
      </div>
    </PageTransition>
  );
};
