import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import "./quote.css";

export const Quote = () => {
  return (
    <></>
    // <motion.div
    //   initial={{ opacity: 0, scale: 0.5 }}
    //   animate={{ opacity: 1, scale: 1 }}
    //   transition={{
    //     duration: 0.8,
    //     delay: 0.7,
    //     ease: [0, 0.71, 0.2, 1.01],
    //   }}
    // >
    //   <Container>
    //     <Row className="mt-2 h-50">
    //       <Col className=" text-center p-2 " xs={12} lg={12}>
    //         <span className="fs-5  animation__one">
    //           Empowering Your Success:
    //         </span>{" "}
    //         <span className="fs-5 animation__two">Expert Solutions,</span>{" "}
    //         <span className="fs-5 animation__three">
    //           Seamless Support Services.
    //         </span>
    //       </Col>
    //     </Row>
    //   </Container>
    // </motion.div>
  );
};
