import { Header } from "../components/header/header";
import { Quote } from "../components/quote/quote";
// import bgLight from "../assets/images/bg_light.jpg";
import { useSelector } from "react-redux";
import { FirstForm } from "../components/firstForm/firstForm";
import { PageTransition } from "../components/pageTransition/pageTransition";
import { ProgressBar } from "../components/progressBar/progressBar";

export const Home = () => {
  const { theme } = useSelector((state) => state.themeChanger);
  // const [switchValue, setSwitchValue] = useState("Light");

  return (
    <PageTransition>
      <div
        className=" d-flex flex-column align-items-center"
        style={{
          height: "105vh",
          background: "rgb(216,217,221)",
          background:
            "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",
          // color: `${theme === "light" ? "black" : "black"}`,
          // background: "#76D8EE",
          // background: "rgb(106,140,215)",
          // background:
          //   "linear-gradient(315deg, rgba(106,140,215,1) 0%, rgba(185,203,242,1) 16%, rgba(231,237,251,1) 33%, rgba(255,255,255,1) 50%, rgba(220,229,249,1) 75%, rgba(185,203,242,1) 84%, rgba(106,140,215,1) 100%)",
        }}
      >
        <div className="align-self-start">
          <Header />
        </div>
        <ProgressBar
          barWidth={"0%"}
          btn1Color={"btn-primary"}
          btn2Color="btn-secondary"
          btn3Color={"btn-secondary"}
        />
        <Quote />
        <FirstForm />
      </div>
    </PageTransition>
  );
};
