import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
  formState: {
    username: "",
    email: "",
    phone: "",
    problemType: "",
    assignedPrograms: "",
    issueDescription: "",
    deviceId: "",
    titleIssue: "",
    attachments_gallery: [],
    assignedPrinters: "",
    liveCamera: "",
    assignedCameras: [],
    noIdPrinter: "",
    typeOfDevice: "",
    scale_name: "",
    scale_location: "",
    doorProblem: "Create new badge for user",
    allData: {},
    person_type: "",
    first_name: "",
    last_name: "",
    department: "",
    company: "",
    subproblem: "",
    door_name: "",
    door_location: "",
    door_type_problem: "",
    door_first_name: "",
    door_last_name: "",
    door_department: "",
    door_issue_subproblem: "",
    badge_site_name: "",
  },
};
export const formHandler = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    usernameHandler: (state, action) => {
      state.formState.username = action.payload;
    },
    emailHandler: (state, action) => {
      state.formState.email = action.payload;
    },
    phoneHandler: (state, action) => {
      state.formState.phone = action.payload;
    },
    problemTypeHandler: (state, action) => {
      state.formState.problemType = action.payload;
    },
    descriptionHandler: (state, action) => {
      state.formState.issueDescription = action.payload;
    },
    titleHandler: (state, action) => {
      state.formState.titleIssue = action.payload;
    },
    attachmentsHandler: (state, action) => {
      state.formState.attachments_gallery = action.payload;
    },
    assignedProgramsHandler: (state, action) => {
      state.formState.assignedPrograms = action.payload;
    },
    deviceIdHandler: (state, action) => {
      state.formState.deviceId = action.payload;
    },
    allDataHandler: (state, action) => {
      state.formState.allData = action.payload;
    },
    printerHandler: (state, action) => {
      state.formState.assignedPrinters = action.payload;
    },
    cameraHandler: (state, action) => {
      state.formState.assignedCameras = action.payload;
    },
    liveCameraHandler: (state, action) => {
      state.formState.liveCamera = action.payload;
    },
    noIdPrinterHandler: (state, action) => {
      state.formState.noIdPrinter = action.payload;
    },
    typeOfDeviceHandler: (state, action) => {
      state.formState.typeOfDevice = action.payload;
    },
    scaleNameHandler: (state, action) => {
      state.formState.scale_name = action.payload;
    },
    scaleLocationHandler: (state, action) => {
      state.formState.scale_location = action.payload;
    },
    doorProblemHandler: (state, action) => {
      state.formState.doorProblem = action.payload;
    },
    personTypeHandler: (state, action) => {
      state.formState.person_type = action.payload;
    },
    firstNameHandler: (state, action) => {
      state.formState.first_name = action.payload;
    },
    lastNameHandler: (state, action) => {
      state.formState.last_name = action.payload;
    },
    departmentHandler: (state, action) => {
      state.formState.department = action.payload;
    },
    companyHandler: (state, action) => {
      state.formState.company = action.payload;
    },
    subProblemHandler: (state, action) => {
      state.formState.subproblem = action.payload;
    },
    doorNameHandler: (state, action) => {
      state.formState.door_name = action.payload;
    },
    doorLocationHandler: (state, action) => {
      state.formState.door_location = action.payload;
    },
    doorTypeProblem: (state, action) => {
      state.formState.door_type_problem = action.payload;
    },
    doorFirstName: (state, action) => {
      state.formState.door_first_name = action.payload;
    },
    doorLastName: (state, action) => {
      state.formState.door_last_name = action.payload;
    },
    doorDepartment: (state, action) => {
      state.formState.door_department = action.payload;
    },
    doorIssueSub: (state, action) => {
      state.formState.door_issue_subproblem = action.payload;
    },
    badgeSiteNameHandler: (state, action) => {
      state.formState.badge_site_name = action.payload;
    },
  },
});
export const {
  usernameHandler,
  emailHandler,
  phoneHandler,
  problemTypeHandler,
  descriptionHandler,
  attachmentsHandler,
  titleHandler,
  assignedProgramsHandler,
  deviceIdHandler,
  allDataHandler,
  printerHandler,
  cameraHandler,
  liveCameraHandler,
  noIdPrinterHandler,
  typeOfDeviceHandler,
  scaleNameHandler,
  scaleLocationHandler,
  doorProblemHandler,
  personTypeHandler,
  firstNameHandler,
  lastNameHandler,
  departmentHandler,
  companyHandler,
  subProblemHandler,
  doorNameHandler,
  doorLocationHandler,
  doorTypeProblem,
  doorFirstName,
  doorLastName,
  doorDepartment,
  doorIssueSub,
  badgeSiteNameHandler,
} = formHandler.actions;
export default formHandler.reducer;
