import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  descriptionHandler,
  doorDepartment,
  doorFirstName,
  doorIssueSub,
  doorLastName,
  doorLocationHandler,
  doorNameHandler,
  doorTypeProblem,
  titleHandler,
} from "../../redux/formSlice";
import { addTicketUrl } from "../../redux/ticketSlice";
import { Loader } from "../loader/loader";
import "./doorsForm.css";
import { UploadPicture } from "../uploadPicture/uploadPicture";
export const DoorsForm = ({ showIt, submitBtn, uploadPhoto }) => {
  const { formState } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [doors, setDoors] = useState([]);

  if (formState.deviceId) {
    dispatch(
      titleHandler(
        `Door : ${formState?.allData?.device?.name}  User : ${formState.username}`
      )
    );
  } else {
    dispatch(
      titleHandler(
        `Door : ${formState.door_name}  User : ${formState.username}`
      )
    );
  }

  const descriptionInput = (e) => {
    if (formState.door_type_problem === "door_access") {
      if (formState.deviceId) {
        dispatch(
          descriptionHandler(
            `Door Access : ${formState.allData.device.name}  User : ${formState.username}`
          )
        );
      } else {
        dispatch(
          descriptionHandler(
            `Door Access : ${formState.door_name}  User : ${formState.username}`
          )
        );
      }
    } else {
      dispatch(descriptionHandler(e.target.value));
    }
  };
  // const issueTitleHandler = (e) => {
  //   dispatch(titleHandler(e.target.value));
  // };
  const galleryImageHandler = (imagesData) => {
    setImages(imagesData);
  };
  const chooseDoorProblem = (e) => {
    dispatch(doorTypeProblem(e.target.value));
  };
  const doorFirst = (e) => {
    dispatch(doorFirstName(e.target.value));
  };
  const doorLast = (e) => {
    dispatch(doorLastName(e.target.value));
  };
  const doorDep = (e) => {
    // console.log("doordep ", e.target.value);
    dispatch(doorDepartment(e.target.value));
  };
  const doorIssueSubProblem = (e) => {
    dispatch(doorIssueSub(e.target.value));
  };

  const doorName = (e) => {
    dispatch(doorNameHandler(e.target.value));
  };

  const doorSite = async (e) => {
    const site = e.target.value;
    dispatch(doorLocationHandler(site));
    await axios
      .get(`https://qr.3f.com/api/doors/doorsByLocation/${site}`)
      .then((response) => {
        setDoors(response.data.data);
      })
      .catch((error) => console.log(error));
  };
  const submitTicketHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("username", formState.username);
    formData.append("email", formState.email);
    formData.append("phone", formState.phone);
    formData.append("problemType", formState.problemType);
    formData.append("assignedPrograms", formState.assignedPrograms);
    formData.append("issueDescription", formState.issueDescription);
    if (formState.deviceId === "" || formState.deviceId === ":id") {
    } else {
      formData.append("deviceId", formState.deviceId);
    }
    formData.append("titleIssue", formState.titleIssue);
    formData.append("assignedPrinters", formState.assignedPrinters);
    formData.append("attachment_camera", formState.liveCamera);
    formData.append("scale_name", formState.scale_name);
    formData.append("scale_location", formState.scale_location);
    formData.append("doorProblem", formState.doorProblem);
    formData.append("person_type", formState.person_type);
    formData.append("first_name", formState.first_name);
    formData.append("last_name", formState.last_name);
    formData.append("company", formState.company);
    formData.append("subProblem", formState.subproblem);
    formData.append("department", formState.department);
    formData.append("door_name", formState.door_name);
    formData.append("door_location", formState.door_location);
    formData.append("door_first_name", formState.door_first_name);
    formData.append("door_last_name", formState.door_last_name);
    formData.append("door_department", formState.door_department);
    formData.append("door_issue_subproblem", formState.door_issue_subproblem);
    formData.append("door_type_problem", formState.door_type_problem);

    if (
      formState.typeOfDevice === "applications" ||
      formState.typeOfDevice === "others"
    ) {
    } else {
      formData.append("type_of_device", formState.typeOfDevice);
    }
    formData.append("printer_name", formState.noIdPrinter.printer_name);
    formData.append("location", formState.noIdPrinter.location);
    formData.append("cameras", JSON.stringify(formState.assignedCameras));
    for (let i = 0; i < images.length; i++) {
      formData.append("attachments_gallery", images[i]);
    }

    await axios
      .post("https://qr.3f.com/api/tickets", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // console.log(response);
        if ((response.data.message = "success")) {
          dispatch(addTicketUrl(response.data));
        } else {
          // dispatch(addTicketUrl(response.data.message));
          // console.log("failed");
        }
      })
      .catch((error) => dispatch(addTicketUrl(error.message)))
      .finally(() => {
        setLoading(false);
        navigate("/success");
      });
  };

  if (loading === true) {
    return <Loader />;
  }
  // console.log("test here ", formState.door_issue_subproblem);
  return (
    <>
      <div
        style={{
          height:
            formState.door_type_problem === ""
              ? `83vh`
              : formState.door_type_problem === "door_access"
              ? `90vh`
              : `100vh`,
        }}
        className={`
       
      container-fluid d-flex flex-column align-items-center  registration-form text-black`}
      >
        <form className=" col-11 col-sm-8 col-lg-4">
          <div className=" form-group d-flex flex-column align-items-center">
            <label className="m-2 text-black ">
              What do you need help with ?
            </label>
            <select
              className=" m-1 form-select rounded-pill"
              aria-label="Default select example"
              value={formState.door_type_problem}
              onChange={(e) => chooseDoorProblem(e)}
            >
              <option value="">Select problem from drop down menu</option>
              <option value="door_access">Grant access to door </option>
              <option value="door_issue">Report issue with door</option>
            </select>
          </div>
          {formState.door_type_problem === "door_access" ? (
            <>
              <div className=" d-flex flex-column align-items-center">
                {/* <p> Identify who requires access:</p> */}
                <div className="col-11 d-flex flex-column align-items-center">
                  <label className="text-black text-center">
                    Identify who requires access:
                  </label>

                  <input
                    className="mb-3 form-control item"
                    type="text"
                    placeholder="Enter first name"
                    value={formState.door_first_name}
                    onChange={(e) => doorFirst(e)}
                  />
                  <input
                    className="mb-3 form-control item"
                    type="text"
                    placeholder="Enter last name"
                    value={formState.door_last_name}
                    onChange={(e) => doorLast(e)}
                  />
                  <input
                    className="mb-3 form-control item"
                    type="text"
                    placeholder="Enter department"
                    value={formState.door_department}
                    onChange={(e) => doorDep(e)}
                  />
                  {formState.deviceId ? (
                    <></>
                  ) : (
                    <>
                      <label className=" text-black text-center">
                        Site Location
                      </label>
                      <select
                        className=" mb-3 form-select rounded-pill"
                        aria-label="Default select example"
                        onChange={(e) => doorSite(e)}
                        value={formState.door_location}
                      >
                        <option value="">Select Site</option>
                        <option value="nevada">Nevada </option>
                        <option value="oakland">Oakland</option>
                      </select>

                      <select
                        className=" mb-3 form-select rounded-pill"
                        aria-label="Default select example"
                        value={formState.door_name}
                        onChange={(e) => doorName(e)}
                      >
                        <option value="">Select Door</option>
                        {doors?.map((door) => (
                          <option value={door.name}>{door.name}</option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {formState.door_type_problem === "door_issue" ? (
            <>
              <div className="form-group d-flex flex-column align-items-center">
                <label className="m-2 text-black text-center">
                  Describe the issue you facing
                </label>
                <select
                  className=" m-1 form-select rounded-pill"
                  aria-label="Default select example"
                  value={formState.doorIssueSub}
                  onChange={(e) => doorIssueSubProblem(e)}
                >
                  <option value="">Select problem from drop down menu</option>
                  <option value="Scanner is solid green">
                    Scanner is solid green
                  </option>
                  <option value="Scanner is blinking red">
                    Scanner is blinking red
                  </option>
                  <option value="Scanner is solid red">
                    Scanner is solid red
                  </option>
                  <option value="Scanner is blinking red and yellow">
                    Scanner is blinking red and yellow
                  </option>
                  <option value="Scanner is blinking red after swipe">
                    Scanner is blinking red after swipe
                  </option>
                </select>
              </div>
            </>
          ) : (
            <></>
          )}
          {formState.door_type_problem === "door_issue" ? (
            <>
              <div className=" form-group d-flex flex-column align-items-center">
                <label className="m-2  mt-2 mb-2 text-center">
                  Can you please provide a detailed description of the issue
                  you're experiencing
                </label>
                <textarea
                  required
                  type="text"
                  className="form-control item"
                  style={{ height: "100px" }}
                  name="issueDescription"
                  onChange={descriptionInput}
                />
              </div>
              <UploadPicture onChoose={galleryImageHandler} />
            </>
          ) : (
            <></>
          )}
          {submitBtn ? (
            <>
              {formState.door_type_problem === "door_access" &&
              !!formState.deviceId == true ? (
                <div className="form-group">
                  <button
                    type="button"
                    disabled={
                      !formState.door_first_name ||
                      !formState.door_last_name ||
                      !formState.door_department
                      //  ||
                      // !formState.door_location ||
                      // !formState.door_name
                    }
                    className="btn btn-block create-account"
                    onClick={submitTicketHandler}
                  >
                    Submit ticket
                  </button>
                </div>
              ) : (
                <></>
              )}

              {formState.door_type_problem === "door_access" &&
              !!formState.deviceId == false ? (
                <div className="form-group">
                  <button
                    type="button"
                    disabled={
                      !formState.door_first_name ||
                      !formState.door_last_name ||
                      !formState.door_department ||
                      !formState.door_location ||
                      !formState.door_name
                    }
                    className="btn btn-block create-account"
                    onClick={submitTicketHandler}
                  >
                    Submit ticket
                  </button>
                </div>
              ) : (
                <></>
              )}
              {formState.door_type_problem === "door_issue" ? (
                <div className="form-group">
                  <button
                    type="button"
                    disabled={
                      !formState.door_issue_subproblem ||
                      !formState.issueDescription ||
                      images.length === 0
                    }
                    className="btn btn-block create-account"
                    onClick={submitTicketHandler}
                  >
                    Submit ticket
                  </button>
                </div>
              ) : (
                <></>
              )}
              {/* <div className="form-group">
                <button
                  type="button"
                  disabled={
                    !formState.door_type_problem ||
                    !formState.door_first_name ||
                    !formState.door_last_name ||
                    !formState.door_department ||
                    !formState.doorIssueSub
                  }
                  className="btn btn-block create-account"
                  onClick={submitTicketHandler}
                >
                  Submit ticket
                </button>
              </div> */}
            </>
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  );
};
