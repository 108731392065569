import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { attachmentsHandler } from "../../redux/formSlice";
import { LiveCamera } from "../liveCamera/liveCamera";

export const UploadPicture = ({ onChoose }) => {
  const [images, setImages] = useState([]);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [userDevice, setUserDevice] = useState("");
  const imageHandler = (e) => {
    const array = [];
    for (let i = 0; i < e.target.files.length; i++) {
      array.push(e.target.files[i]);
    }
    setImages(array);
    onChoose(array);
  };

  useEffect(() => {
    const platform = navigator.platform;

    if (
      platform === "Windows" ||
      "MacIntel" ||
      "Macintosh" ||
      "Mac68K" ||
      "Linux x86_64 X1" ||
      "Win16" ||
      "Win32"
    ) {
      setUserDevice("pc");
    } else {
      setUserDevice("phone");
    }
  });
  const chooseUpload = (e) => {
    e.preventDefault();
    setVisible(true);
  };
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <label className="mb-2 text-center">
          Please upload a photo illustrating the issue you're experiencing
        </label>
        {visible ? (
          <>
            <div className="col-lg-12 col-sm-12 d-flex flex-column align-items-center justify-content-center">
              <input
                type="file"
                id="images"
                accept="image/png image/jpeg image/jpg"
                className="mb-1 ps-3 fix-margin rounded-pill"
                name="image"
                onChange={imageHandler}
                multiple
              />
            </div>
            {userDevice === "phone" && (
              <>
                {" "}
                <div className="d-flex justify-content-center">
                  <div id="or">OR</div>
                </div>
                <LiveCamera />
              </>
            )}
          </>
        ) : (
          <>
            <button
              className="btn btn-warning rounded-pill col-12"
              onClick={chooseUpload}
            >
              Click To Upload Photo
            </button>
          </>
        )}
      </div>
    </>
  );
};
