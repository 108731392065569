import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import successImg from "../../assets/images/successImg.png";
export const Success = () => {
  const { ticket } = useSelector((state) => state.ticket);
  return (
    <Container fluid>
      <Row>
        <Col
          className="text-center bg-white text-dark m-3"
          style={{ borderRadius: "10px", padding: "20px" }}
        >
          {/* Thank you for reaching out to the 3F Support team. Your ticket{" "}
          <Link to={ticket}> Number</Link> has been successfully submitted. Our
          team is currently reviewing your ticket and will contact you within
          the next 5-10 minutes. We appreciate your patience. */}
          <div className="">
            <img
              className="mb-3 w-75 h-75"
              src={successImg}
              alt="success image"
            />
          </div>
          "Thank you for reaching out to the 3F Support Team. Your ticket{" "}
          <a href={ticket.ticketUrl} target="_blank">
            {ticket.ticketID}
          </a>{" "}
          has been successfully submitted. Our team is now reviewing your
          inquiry and will contact you within the next 5-10 minutes. We
          appreciate your patience and are here to assist you."
        </Col>
      </Row>
    </Container>
  );
};
