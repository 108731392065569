import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./firstForm.css";
import { PatternFormat } from "react-number-format";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  allDataHandler,
  deviceIdHandler,
  emailHandler,
  phoneHandler,
  problemTypeHandler,
  usernameHandler,
} from "../../redux/formSlice";

export const FirstForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputValidation, setInputValidation] = useState(false);
  const [idRoute, setIdRoute] = useState("");
  const { formState } = useSelector((state) => state.form);
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const usernameInput = (e) => {
    dispatch(usernameHandler(e.target.value));
  };

  const emailInput = (e) => {
    // const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setInputValidation(false);
      dispatch(emailHandler(e.target.value));
    } else {
      // setInputValidation(true);
      dispatch(emailHandler(""));
    }
  };
  const focusOutFn = (e) => {
    // const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setInputValidation(false);
    } else {
      setInputValidation(true);
    }
  };

  const phoneInput = (e) => {
    dispatch(phoneHandler(e.target.value));
  };

  const nextPageHandler = (e) => {
    e.preventDefault();
    if (id === ":id") {
      navigate("/issuepage");
    } else {
      dispatch(problemTypeHandler("doors"));
      navigate(idRoute);
    }
    // navigate("/issuepage");
  };
  useEffect(() => {
    const fetchDevice = async () => {
      axios
        .get(`https://qr.3f.com/api/tickets/findInfo/${id}`)
        .then((response) => {
          setIdRoute(response.data.route);
          dispatch(allDataHandler(response.data));
          dispatch(deviceIdHandler(id));
        })
        .catch((error) => console.log(error));
    };
    fetchDevice();
  }, [dispatch, id]);
  return (
    <>
      <div className="container-fluid d-flex justify-content-center registration-form">
        <form className="col-11 col-sm-8 col-lg-4">
          {formState?.allData?.device?.name ? (
            <>
              {" "}
              <h3 className="titleIssue">
                Report Issue With: <br /> {formState?.allData?.device?.name}
              </h3>
            </>
          ) : (
            <></>
          )}
          <p> Enter your contact info below:</p>
          <div className="form-group">
            <label>Full Name</label>
            <input
              type="text"
              className="form-control item"
              id="username"
              placeholder="Full Name..."
              onChange={usernameInput}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              className="form-control item"
              id="password"
              placeholder="name@email.com"
              onChange={emailInput}
              onBlur={focusOutFn}
            />
            {inputValidation ? (
              <span className="text-danger ms-2">Incorrect Email Format</span>
            ) : (
              <></>
            )}
          </div>
          {/* <p> */}
          {/* {inputValidation ? (
            <span className="text-danger ml-5">Incorrect Email Format</span>
          ) : (
            <></>
          )} */}
          {/* </p> */}
          <div className="form-group">
            <label>Phone Number</label>

            {/* <input
              className="form-control item"
              id="email"
              type="number"
              placeholder="(201)-555-0123"
              onChange={phoneInput}
            /> */}
            <PatternFormat
              className="form-control item"
              format="+1 (###) ###-####"
              // value="123456789"
              // valueIsNumericString={true}
              id="email"
              // type="number"
              placeholder="(201)-555-0123"
              onChange={phoneInput}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-block create-account"
              variant="primary"
              type="submit"
              disabled={
                !formState.username || !formState.email || !formState.phone
              }
              onClick={nextPageHandler}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
