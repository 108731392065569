import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/logo 3.png";

import { motion } from "framer-motion";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../../redux/themeSlice";

export const Header = () => {
  const { theme } = useSelector((state) => state.themeChanger);
  // const [switchValue, setSwitchValue] = useState("Light");
  const dispatch = useDispatch();
  const switchHandler = () => {
    dispatch(changeTheme("dark"));
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Navbar
          // style={{
          //   backgroundColor: `${theme === "light" ? "#f8f9fa" : "#242526"}`,
          // }}
          expand="lg"
        >
          <Container>
            <Navbar.Brand href="#home">
              <img src={logo} alt="logo" height="88px" />
            </Navbar.Brand>
            {/* <Navbar.Brand href="#home">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={`${theme} Theme`}
                  onChange={switchHandler}
                  className="text-primary"
                />
              </Form>
            </Navbar.Brand> */}
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse> */}
          </Container>
        </Navbar>
      </motion.div>
    </div>
  );
};
