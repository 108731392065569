import "./error.css";
export const Error = () => {
  return (
    <div className="error">
      <div className="fof">
        <h1>Error 404 page Not found</h1>
      </div>
    </div>
  );
};
