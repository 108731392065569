import { motion } from "framer-motion";

export const PageTransition = ({ children }) => {
  const widthAnimation = {
    initial: { width: 0 },
    animate: { width: "100%" },
    exit: { width: "100%", x: window.innerWidth },
  };
  return (
    // <motion.div
    //   initial={{ opacity: 0 }}
    //   animate={{ opacity: 1 }}
    //   exit={{ opacity: 0 }}
    //   transition={{
    //     ease: "easeIn",
    //     duration: 0.5,
    //   }}
    // >
    //   {children}
    // </motion.div>
    <motion.div
      transition={{
        ease: "easeInOut",
        duration: 0.5,
      }}
      variants={widthAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};
