import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { noIdPrinterHandler } from "../../redux/formSlice";

export const NoIdPrinter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [printerName, setPrinterName] = useState();
  const [printerLocation, setPrinterLocation] = useState();
  const noIdPrinter = {
    printer_name: "",
    location: "",
  };
  const navigateHandler = (e) => {
    e.preventDefault();
    noIdPrinter.printer_name = printerName;
    noIdPrinter.location = printerLocation;

    dispatch(noIdPrinterHandler(noIdPrinter));
    navigate("/details");
  };
  return (
    <>
      {/* <div className="container-fluid d-flex flex-column">
        <div className="w-100 border border-1">
          <Form className="m-2">
            <Form.Group className="mb-3">
              <Form.Label>Name of the printer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter printer name"
                onChange={(e) => {
                  setPrinterName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Location of the printer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter printer location"
                onChange={(e) => {
                  setPrinterLocation(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="align-self-center mt-3">
          <Button variant="outline-primary" onClick={navigateHandler}>
            Continue
          </Button>
        </div>
      </div> */}
      <div className="registration-form">
        <form>
          <Form.Label style={{ color: "black" }}>
            Name of the printer
          </Form.Label>
          <div className="form-group">
            <input
              type="text"
              className="form-control item"
              placeholder="Enter printer name"
              onChange={(e) => {
                setPrinterName(e.target.value);
              }}
            />
          </div>
          <Form.Label style={{ color: "black" }}>
            Location of the printer
          </Form.Label>
          <div className="form-group">
            <input
              className="form-control item"
              type="text"
              placeholder="Enter printer location"
              onChange={(e) => {
                setPrinterLocation(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-block create-account"
              onClick={navigateHandler}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
