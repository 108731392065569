import { useState } from "react";
import { useSelector } from "react-redux";
import { CameraForm } from "../components/cameraForm/cameraForm";
import { SelectSearch } from "../components/selectSearch/selectSearch";
import { Header } from "../components/header/header";
import "./notHere.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cameraHandler } from "../redux/formSlice";
export const CameraPage = () => {
  const { theme } = useSelector((state) => state.themeChanger);
  const { formState } = useSelector((state) => state.form);
  const [isForm, setIsForm] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [cameras, setCameras] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate("/details");
  };
  const chooseCameraForm = (info) => {
    setIsForm(info);
  };
  const selectedValue = (theValue) => {
    // console.log(theValue);
    setBtnDisabled(false);
    getCamerasByLocation(theValue.value);
  };
  const cameraName = (value) => {
    let selectedCameras = [];
    selectedCameras.push(value.value);
    dispatch(cameraHandler(selectedCameras));
    // console.log("cameraName", value.value);
  };
  const getCamerasByLocation = (loc) => {
    axios
      .get(`https://qr.3f.com/api/cameras/camerasByLocation/${loc}`)
      .then((res) => {
        const newArray = res.data.data.map((item) => {
          return {
            label: item.camera_name,
            value: item._id,
          };
        });
        // console.log(" newArray", newArray);
        setCameras(newArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const locations = [
    {
      id: 0,
      label: "Oakland",
      value: "oakland",
    },
    {
      id: 1,
      label: "Nevada",
      value: "nevada",
    },
    {
      id: 2,
      label: "Post Falls",
      value: "postfalls",
    },
    {
      id: 3,
      label: "Austin",
      value: "austin",
    },
    {
      id: 4,
      label: "Newark",
      value: "newark",
    },
    {
      id: 5,
      label: "Los Angelos",
      value: "losangelos",
    },
  ];
  return (
    <>
      {formState?.problemType === "camera" ? (
        <div
          style={{
            height: "130vh",
            // color: `${theme === "light" ? "black" : "white"}`,
            background: "rgb(216,217,221)",
            background:
              "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",
          }}
        >
          <Header />
          {isForm ? (
            <>
              {" "}
              {/* <CameraForm onChoose={selectedValue} /> */}
              <div className="col-12 text-white text-center">
                Which camera are you having an issue with
              </div>
              <SelectSearch
                showIt={true}
                options={cameras}
                onChoose={cameraName}
                onConfirm={navigateHandler}
              />
            </>
          ) : (
            <>
              {" "}
              <SelectSearch
                problemName="camera"
                onConfirm={chooseCameraForm}
                options={locations}
                onChoose={selectedValue}
                showIt={true}
                disabled={btnDisabled}
              />
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            height: "130vh",
            // color: `${theme === "light" ? "black" : "white"}`,
            background: "rgb(216,217,221)",
            background:
              "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",
          }}
        >
          <Header />
          <section className="col-12">
            <div className="alert alert-2-success">
              <h3 className="alert-title">OPPS!!</h3>
              <p className="alert-content">
                You are not supposed to be here !!
              </p>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
