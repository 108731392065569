import { Button, ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { assignedProgramsHandler } from "../../redux/formSlice";
import { NoIdApplications } from "../noIdApplications/noIdApplications";
import "./applicationsForm.css";
export const ApplicationsForm = () => {
  const dispatch = useDispatch();
  const { formState } = useSelector((state) => state.form);
  const [applications, setApplications] = useState([]);
  const [selectedApp, setSelectedApp] = useState("Select the app");
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate("/details");
  };

  useEffect(() => {
    const fetchDevice = async () => {
      axios
        .get(`https://qr.3f.com/api/${formState.deviceId}`)
        .then((response) => setApplications(response.data.applications))
        .catch((error) => console.log(error));
    };
    fetchDevice();
  }, []);

  const applicationHandler = (application) => {
    dispatch(assignedProgramsHandler(application));
    setSelectedApp(application);
  };
  return (
    <>
      {formState.deviceId == "" || formState.deviceId == ":id" ? (
        <NoIdApplications />
      ) : (
        <NoIdApplications />
        // <div className="fluid d-flex flex-column mt-4  justify-content-center">
        //   <div className="align-self-center">
        //     Which application do you have a problem with ?
        //   </div>
        //   <div className="mb-2 align-self-center mt-3">
        //     <DropdownButton
        //       className="align-self-center"
        //       as={ButtonGroup}
        //       id={`dropdown-button-drop-down`}
        //       drop="down"
        //       variant="danger"
        //       title={selectedApp}
        //     >
        //       {applications?.map((application) => (
        //         <Dropdown.Item
        //           key={application}
        //           onClick={() => applicationHandler(application)}
        //         >
        //           {application}
        //         </Dropdown.Item>
        //       ))}
        //     </DropdownButton>
        //   </div>

        //   <div className="align-self-center mt-3">
        //     <Button variant="outline-primary" onClick={navigateHandler}>
        //       Continue
        //     </Button>
        //   </div>
        // </div>
      )}
    </>
  );
};
