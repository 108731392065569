import { useState } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { assignedProgramsHandler } from "../../redux/formSlice";
import { SelectSearch } from "../selectSearch/selectSearch";
import "./noIdapplicationsForm.css";

const noIdApps = [
  {
    id: 1,
    label: "Microsoft Office outlook",
    value: "Microsoft Office outlook",
  },
  { id: 2, label: "Microsoft Office word", value: "Microsoft Office word" },
  {
    id: 3,
    label: "Microsoft office powerpoint",
    value: "Microsoft office powerpoint",
  },
  { id: 4, label: "Printer driver app", value: "Printer driver app" },
  { id: 5, label: "GP 2018", value: "GP 2018" },
  { id: 6, label: "ABS", value: "ABS" },
  { id: 7, label: "Scale server", value: "Scale server" },
  { id: 8, label: "Drive Mapping", value: "Drive Mapping" },
  { id: 9, label: "TeamViewer", value: "TeamViewer" },
  { id: 10, label: "Snagit", value: "Snagit" },
  { id: 11, label: "Adobe acrobat PDF", value: "Adobe acrobat PDF" },
  { id: 12, label: "Quick books", value: "Quick books" },
  { id: 13, label: "Zoom meeting", value: "Zoom meeting" },
];
export const NoIdApplications = () => {
  const [selectedApp, setSelectedApp] = useState("Select the app");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateHandler = () => {
    navigate("/details");
  };

  const applicationHandler = (info) => {
    dispatch(assignedProgramsHandler(info.value));
    setSelectedApp(info.value);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="fluid d-flex flex-column mt-4  justify-content-center text-black">
          <div className="align-self-center">
            Which application do you have a problem with ?
          </div>
          <SelectSearch options={noIdApps} onChoose={applicationHandler} />
          <div className="align-self-center mt-3">
            {/* <Button variant="outline-primary" onClick={navigateHandler}>
              Continue
            </Button> */}
            <div className="form-group">
              <button
                className="btn btn-block create-account"
                onClick={navigateHandler}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
