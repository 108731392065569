import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Home } from "./pages/home";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Details } from "./pages/details";
import { NotFound } from "./pages/notFound";
import { SecondPage } from "./pages/secondPage";
import { FinalPage } from "./pages/finalPage";
import { CameraPage } from "./pages/cameraPage";
import { PrinterPage } from "./pages/printerPage";
import { ApplicationPage } from "./pages/applicationPage";
import { AnimatePresence } from "framer-motion";
import { ScalesPage } from "./pages/scalesPage";
import { BadgesPage } from "./pages/badgesPage";
import { DoorsPage } from "./pages/doorsPage";
import ScrollToTop from "./components/scrollToTop/scrollToTop";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <AnimatePresence initial={false}>
        <ScrollToTop />
        <Routes location={location} key={location.pathname}>
          {" "}
          <Route path="/" element={<Navigate to="/home/:id" replace />} />
          <Route path="/home/:id" element={<Home />} />
          <Route path="/success" element={<Details />} />
          <Route path="/issuepage" element={<SecondPage />} />
          <Route path="camera" element={<CameraPage />} />
          <Route path="printer" element={<PrinterPage />} />
          <Route path="applications" element={<ApplicationPage />} />
          <Route path="scales" element={<ScalesPage />} />
          <Route path="/details" element={<FinalPage />} />
          <Route path="*" element={<Navigate to="/notfound" replace />} />
          <Route path="notfound" element={<NotFound />} />
          <Route path="doors" element={<DoorsPage />} />
          <Route
            path="securitybadges"
            element={
              <>
                <BadgesPage />
              </>
            }
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
