import Select from "react-select";
export const SelectSearch = ({
  onConfirm,
  options,
  onChoose,
  problemName,
  showIt,
  disabled,
}) => {
  const handleOnSelect = (item) => {
    // the item selected
    onChoose(item);
  };
  const cameraLocationHandler = (item) => {
    onConfirm(true, item);
  };
  return (
    <>
      <div className="col-12  d-flex flex-column align-items-center">
        {showIt && (
          <div className="col-6 text-center text-white">
            What site is the {problemName} located ?
          </div>
        )}

        <div className="col-10 col-md-8 mt-3 ">
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            onChange={handleOnSelect}
            name="color"
            options={options}
          />
        </div>
        {showIt && (
          <button
            className="btn btn-block create-account"
            variant="primary"
            onClick={cameraLocationHandler}
            disabled={disabled}
          >
            Continue
          </button>
        )}
      </div>
    </>
  );
};
