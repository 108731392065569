import { SyncLoader } from "react-spinners";

export const Loader = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-12 mt-5 d-flex  justify-content-center">
          {" "}
          <SyncLoader color="#36d7b7" />
        </div>
        <div className="col-lg-12 text-center mt-4 text-black">
          We are creating your ticket 👌
        </div>
      </div>
    </div>
  );
};
