import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../loader/loader";
import { addTicketUrl } from "../../redux/ticketSlice";
import axios from "axios";
import "./badgesForm.css";
import {
  doorProblemHandler,
  personTypeHandler,
  firstNameHandler,
  lastNameHandler,
  companyHandler,
  departmentHandler,
  subProblemHandler,
  doorNameHandler,
  titleHandler,
  descriptionHandler,
  doorLocationHandler,
  badgeSiteNameHandler,
} from "../../redux/formSlice";
import { FinalForm } from "../finalForm/finalForm";
import { BadgeFinalForm } from "../badgeFinalForm/badgeFinalForm";
import { UploadPicture } from "../uploadPicture/uploadPicture";

export const BadgesForm = () => {
  const { formState } = useSelector((state) => state.form);

  const [badgeFor, setBadgeFor] = useState("");
  const [images, setImages] = useState([]);
  const [pickedBadge, setPickedBadge] = useState(0);
  const [doorAccess, setDoorAccess] = useState("");
  const [doors, setDoors] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // dispatch(doorProblemHandler("Create new badge for user"));

  // console.log("allData", formState.allData);
  dispatch(
    titleHandler(` ${formState.doorProblem} user : ${formState.username}`)
  );
  dispatch(
    descriptionHandler(`${formState.doorProblem} user : ${formState.username}`)
  );

  const doorProblem = (value) => {
    dispatch(doorProblemHandler(value));
    dispatch(firstNameHandler(""));
    dispatch(lastNameHandler(""));
    dispatch(personTypeHandler(""));
    dispatch(companyHandler(""));
    dispatch(subProblemHandler(""));
    dispatch(doorNameHandler(""));
    dispatch(doorLocationHandler(""));
    dispatch(departmentHandler(""));
  };
  const personType = (value) => {
    // console.log(value);
    dispatch(personTypeHandler(value));
  };
  const firstNameChange = (value) => {
    dispatch(firstNameHandler(value));
  };
  const lastNameChange = (value) => {
    dispatch(lastNameHandler(value));
  };
  const departmentChange = (value) => {
    // console.log(value);
    dispatch(departmentHandler(value));
  };
  const siteChange = (value) => {
    dispatch(badgeSiteNameHandler(value));
  };
  const companyChange = (value) => {
    dispatch(companyHandler(value));
  };

  const subproblemChange = (value) => {
    dispatch(subProblemHandler(value));
  };
  const doorNameChange = (value) => {
    dispatch(doorNameHandler(value));
  };
  const doorLocationChange = (value) => {
    dispatch(doorLocationHandler(value));
  };
  const pickedBadgeHandler = (picked) => {
    setPickedBadge(picked);
    if (picked === 1) {
      dispatch(doorProblemHandler("Create new badge for door"));
    } else if (picked === 2) {
      dispatch(doorProblemHandler("Replace lost or stolen badge"));
    } else if (picked === 3) {
      dispatch(doorProblemHandler("Update Badge permission"));
    }
  };

  const doorName = (e) => {
    dispatch(doorNameHandler(e.target.value));
  };

  const doorSite = async (e) => {
    const site = e.target.value;
    dispatch(doorLocationHandler(site));
    await axios
      .get(`https://qr.3f.com/api/doors/doorsByLocation/${site}`)
      .then((response) => {
        setDoors(response.data.data);
      })
      .catch((error) => console.log(error));
  };
  const galleryImageHandler = (imagesData) => {
    setImages(imagesData);
  };
  const submitTicketHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("username", formState.username);
    formData.append("email", formState.email);
    formData.append("phone", formState.phone);
    formData.append("problemType", formState.problemType);

    formData.append("issueDescription", formState.issueDescription);
    if (formState.deviceId === "" || formState.deviceId === ":id") {
    } else {
      formData.append("deviceId", formState.deviceId);
    }
    formData.append("titleIssue", formState.titleIssue);
    formData.append("attachment_camera", formState.liveCamera);
    formData.append("doorProblem", formState.doorProblem);
    formData.append("person_type", formState.person_type);
    formData.append("first_name", formState.first_name);
    formData.append("last_name", formState.last_name);
    formData.append("company", formState.company);
    formData.append("subProblem", formState.subproblem);
    formData.append("department", formState.department);
    formData.append("door_name", formState.door_name);
    formData.append("door_location", formState.door_location);
    formData.append("badge_site_name", formState.badge_site_name);
    if (
      formState.typeOfDevice === "applications" ||
      formState.typeOfDevice === "others"
    ) {
    } else {
      formData.append("type_of_device", formState.typeOfDevice);
    }
    for (let i = 0; i < images.length; i++) {
      formData.append("attachments_gallery", images[i]);
    }

    await axios
      .post("https://qr.3f.com/api/tickets", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // console.log(response);
        if ((response.data.message = "success")) {
          dispatch(addTicketUrl(response.data));
        } else {
          // dispatch(addTicketUrl(response.data.message));
          // console.log("failed");
        }
      })
      .catch((error) => dispatch(addTicketUrl(error.message)))
      .finally(() => {
        setLoading(false);
        navigate("/success");
      });
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div
      style={{ height: "83vh" }}
      className={`  container-fluid d-flex flex-column align-items-center badges-form`}
    >
      {/* <div className=" row justify-content-center "> */}
      {/* <div className="col-12 text-center">
        {/* <p> What do you need help with?</p> 
      </div> */}
      <form className="col-11 col-sm-8 col-lg-4 d-flex flex-column align-items-center">
        {/* <BadgeFinalForm problem={formState.doorProblem} /> */}
        {pickedBadge > 0 ? (
          <>
            {" "}
            {pickedBadge === 1 ? (
              <>
                {" "}
                {/* <div className=" row  justify-content-center"> */}
                <div className="d-flex flex-column align-items-center">
                  <div className="text-center mb-2">Select user type : </div>
                  <select
                    className=" mb-4 form-select rounded-pill"
                    aria-label="Default select example"
                    value={formState.person_type}
                    onChange={(e) => {
                      personType(e.target.value);
                      setBadgeFor(e.target.value);
                    }}
                  >
                    <option value="">
                      Select User Type from drop down menu
                    </option>
                    <option value="employee">Employee</option>
                    <option value="contractor">Contractor</option>
                  </select>
                  {badgeFor === "" ? (
                    <></>
                  ) : (
                    <>
                      {badgeFor === "employee" ? (
                        <>
                          <input
                            className="mb-2 form-control item rounded-pill"
                            type="text"
                            placeholder="Enter first name"
                            value={formState.first_name}
                            onChange={(e) => {
                              firstNameChange(e.target.value);
                            }}
                          />
                          <input
                            className="mb-2 form-control item rounded-pill"
                            type="text"
                            placeholder="Enter last name"
                            value={formState.last_name}
                            onChange={(e) => {
                              lastNameChange(e.target.value);
                            }}
                          />
                          <input
                            className="mb-2 form-control item rounded-pill"
                            type="text"
                            value={formState.department}
                            placeholder="Enter Department Name"
                            onChange={(e) => {
                              departmentChange(e.target.value);
                            }}
                          />
                          <select
                            className=" mb-3 form-select rounded-pill"
                            aria-label="Default select example"
                            onChange={(e) => {
                              siteChange(e.target.value);
                            }}
                            value={formState.badge_site_name}
                          >
                            <option value="">Select Site</option>
                            <option value="nevada">Nevada </option>
                            <option value="oakland">Oakland</option>
                          </select>
                          <UploadPicture onChoose={galleryImageHandler} />
                          <div className="form-group">
                            <button
                              type="button"
                              disabled={
                                !formState.first_name ||
                                !formState.last_name ||
                                !formState.department ||
                                !formState.badge_site_name ||
                                !images.length > 0
                              }
                              className="btn btn-block create-account"
                              onClick={submitTicketHandler}
                            >
                              Submit ticket
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <input
                            className="mb-2 form-control item rounded-pill"
                            type="text"
                            placeholder="Enter first name"
                            value={formState.first_name}
                            onChange={(e) => {
                              firstNameChange(e.target.value);
                            }}
                          />
                          <input
                            className="mb-2 form-control item rounded-pill"
                            type="text"
                            placeholder="Enter last name"
                            value={formState.last_name}
                            onChange={(e) => {
                              lastNameChange(e.target.value);
                            }}
                          />

                          <input
                            className="mb-2 form-control item rounded-pill"
                            type="text"
                            value={formState.company}
                            placeholder="Enter Company name"
                            onChange={(e) => {
                              companyChange(e.target.value);
                            }}
                          />
                          <input
                            className="mb-3 form-control item rounded-pill"
                            type="text"
                            value={formState.badge_site_name}
                            placeholder="Enter Site Name"
                            onChange={(e) => {
                              siteChange(e.target.value);
                            }}
                          />
                          <UploadPicture onChoose={galleryImageHandler} />

                          <div className="form-group ">
                            <button
                              type="button"
                              disabled={
                                !formState.first_name ||
                                !formState.last_name ||
                                !formState.company ||
                                !formState.badge_site_name ||
                                images.length === 0
                              }
                              className="btn btn-block create-account"
                              onClick={submitTicketHandler}
                            >
                              Submit ticket
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* </div> */}
              </>
            ) : (
              <></>
            )}
            {pickedBadge === 2 ? (
              <>
                {" "}
                <div className=" d-flex flex-column align-items-center">
                  {/* <div className="bg-success col-md-8"> */}
                  <p className="fw-bold text-center">Badge Reprint Request</p>
                  <p className="text-center">
                    In order to proceed with reprinting identification badge,
                    please provide the following information:
                  </p>
                  <input
                    className="m-1 form-control item rounded-pill"
                    type="text"
                    placeholder="Enter first name"
                    value={formState.first_name}
                    onChange={(e) => {
                      firstNameChange(e.target.value);
                    }}
                  />
                  <input
                    className="m-1 form-control item rounded-pill"
                    type="text"
                    placeholder="Enter last name"
                    value={formState.last_name}
                    onChange={(e) => {
                      lastNameChange(e.target.value);
                    }}
                  />
                  {/* </div> */}
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    disabled={!formState.first_name || !formState.last_name}
                    className="btn btn-block create-account"
                    onClick={submitTicketHandler}
                  >
                    Submit ticket
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            {pickedBadge === 3 ? (
              <>
                <div className=" d-flex flex-column align-items-center">
                  {/* <div className="col-md-8"> */}
                  <p className="fw-bold">Request for Badge Permission Update</p>
                  <p>Badge Holder(s) Information:</p>
                  <input
                    className="mb-3 form-control item rounded-pill"
                    type="text"
                    placeholder="Enter first name"
                    value={formState.first_name}
                    onChange={(e) => {
                      firstNameChange(e.target.value);
                    }}
                  />
                  <input
                    className="mb-3 form-control item rounded-pill"
                    type="text"
                    placeholder="Enter last name"
                    value={formState.last_name}
                    onChange={(e) => {
                      lastNameChange(e.target.value);
                    }}
                  />
                  <div className="text-center mb-2">
                    Requested Permission Changes:
                  </div>
                  <select
                    className="mb-3 m-1 form-select rounded-pill"
                    aria-label="Default select example"
                    value={formState.subproblem}
                    onChange={(e) => {
                      subproblemChange(e.target.value);
                      setDoorAccess(e.target.value);
                    }}
                  >
                    <option value="">
                      What action would you like to take regarding the site
                      access permissions?
                    </option>
                    <option value="add">Add door access</option>
                    <option value="remove">Remove door access</option>
                    <option value="terminate">Terminate Site Access</option>
                  </select>
                  {doorAccess === "add" || doorAccess === "remove" ? (
                    <>
                      <label className="mb-2 text-black ">
                        Choose Door Location
                      </label>
                      <select
                        className=" mb-3 form-select rounded-pill"
                        aria-label="Default select example"
                        onChange={(e) => doorSite(e)}
                        value={formState.door_location}
                      >
                        <option value="">Select Site</option>
                        <option value="nevada">Nevada </option>
                        <option value="oakland">Oakland</option>
                      </select>
                      <select
                        className=" mb-3 form-select rounded-pill"
                        aria-label="Default select example"
                        value={formState.door_name}
                        onChange={(e) => doorName(e)}
                      >
                        <option value="">Select Door</option>
                        {doors?.map((door) => (
                          <option value={door.name}>{door.name}</option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* </div> */}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <button
              className="mb-2  btn btn-light border rounded-pill"
              onClick={() => pickedBadgeHandler(1)}
            >
              Create a new badge
            </button>
            <button
              className="mb-2  btn btn-light border rounded-pill"
              onClick={() => pickedBadgeHandler(2)}
            >
              Replace lost or stolen badge
            </button>
            <button
              className="mb-2  btn btn-light border rounded-pill"
              onClick={() => pickedBadgeHandler(3)}
            >
              Update Badge permission
            </button>
          </>
        )}
      </form>
      {/* </div> */}
    </div>
  );
};
