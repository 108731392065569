import { useSelector } from "react-redux";
import { DoorsForm } from "../components/doorsForm/doorsForm";
import { Header } from "../components/header/header";
import { ProgressBar } from "../components/progressBar/progressBar";
import { Quote } from "../components/quote/quote";

export const DoorsPage = () => {
  const { theme } = useSelector((state) => state.themeChanger);
  const { formState } = useSelector((state) => state.form);

  return (
    <>
      {formState?.problemType === "doors" ? (
        <div
          className="h-100 d-flex flex-column align-items-center"
          style={{
            height: "130vh",
            // color: `${theme === "light" ? "white" : "white"}`,
            background: "rgb(216,217,221)",
            background:
              "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",
          }}
        >
          <div className="align-self-start">
            <Header />
          </div>
          <ProgressBar
            barWidth={"100%"}
            btn1Color={"btn-primary"}
            btn2Color="btn-primary"
            btn3Color={"btn-primary"}
          />
          <Quote />
          <DoorsForm showIt={true} uploadPhoto={true} submitBtn={true} />
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center"
          style={{
            height: "130vh",
            // color: `${theme === "light" ? "black" : "white"}`,
            background: "rgb(216,217,221)",
            background:
              "linear-gradient(315deg, rgba(216,217,221,1) 16%, rgba(231,232,232,1) 25%, rgba(255,255,255,1) 50%, rgba(231,232,232,1) 75%, rgba(216,217,221,1) 84%)",
          }}
        >
          <div className="align-self-start">
            <Header />
          </div>
          <Quote />
          {/* <div>You are not supposed to be here !!</div> */}
          <section className="col-12">
            <div className="alert alert-2-success">
              <h3 className="alert-title">OPPS!!</h3>
              <p className="alert-content">
                You are not supposed to be here !!
              </p>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
