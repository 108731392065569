const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  theme: "light",
};

export const themeChanger = createSlice({
  name: "themeChanger",
  initialState: initialState,
  reducers: {
    changeTheme: (state, action) => {
      if (state.theme === "light") {
        state.theme = action.payload;
      } else {
        state.theme = "light";
      }
    },
  },
});
export const { changeTheme } = themeChanger.actions;
export default themeChanger.reducer;
