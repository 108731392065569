import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Camera } from "react-camera-pro";
import { useDispatch, useSelector } from "react-redux";
import { liveCameraHandler } from "../../redux/formSlice";
import cameraIcon from "../../assets/images/cam.png";
import "./liveCamera.css";
export const LiveCamera = () => {
  const [startCamera, setStartCamera] = useState(false);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const dispatch = useDispatch();
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [devices, setDevices] = useState([]);
  const [activeDeviceId, setActiveDeviceId] = useState(undefined);

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind === "videoinput");
      setDevices(videoDevices);
    })();
  });
  const saveCaptureHandler = (e) => {
    e.preventDefault();
    dispatch(liveCameraHandler(camera.current.takePhoto()));
    setImage(camera.current.takePhoto());
    // console.log(camera.current.takePhoto());
  };
  return (
    <>
      {/* <Form.Label>
        <img
          style={{ height: "40px" }}
          src={cameraIcon}
          alt="upload from camera"
        />{" "}
        Take a picture of your entire screen including error
      </Form.Label> */}
      <div className="d-flex justify-content-center">
        {/* <button
          className="btn btn-success m-2"
          onClick={(e) => {
            e.preventDefault();
            setStartCamera(!startCamera);
          }}
        >
          {startCamera === false ? "start the camera" : "cancel the camera"}
        </button> */}
        <button
          type="button"
          className="button m-2"
          onClick={(e) => {
            e.preventDefault();
            setStartCamera(!startCamera);
          }}
        >
          <span className="button__text">
            {" "}
            {startCamera === false ? "Take a live photo" : "cancel the camera"}
          </span>
          <span className="button__icon">
            <img
              style={{ height: "40px" }}
              src={cameraIcon}
              alt="upload from camera"
            />
          </span>
        </button>

        {startCamera === true ? (
          <>
            <Camera
              ref={camera}
              aspectRatio={16 / 9}
              numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
              videoSourceDeviceId={activeDeviceId}
            />
            <button className="btn btn-danger" onClick={saveCaptureHandler}>
              Capture
            </button>
            <img width="100vw" height="100vh" className="m-3" src={image} />
            <select
              onChange={(event) => {
                setActiveDeviceId(event.target.value);
              }}
            >
              {devices?.map((d) => (
                <option key={d.deviceId} value={d.deviceId}>
                  {d.label}
                </option>
              ))}
            </select>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
